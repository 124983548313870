import React from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

import PageTemplate from "../../templates/internal"
import HomeTemplate from "../../templates/home"
import ContactTemplate from "../../templates/contact"
import CampaignTemplate from "../../templates/campaign"
import NewsTemplate from "../../templates/news"
import StaffTemplate from "../../templates/staff"
import LoadingBars from "./Loading"

import {
  pageData,
  navQuery,
  footerQuery,
  latestPostsQuery,
  categoriesQuery,
} from "../../fragments/data"

const cleanedPageData = pageData.replace(/WPGraphQL_/g, "")

const GET_POSTS = gql`
query($postID: ID!) {
  templateData: page(idType: DATABASE_ID, id: $postID) {
    ${cleanedPageData}
  }
  #Static Queries
  ${navQuery}
  ${footerQuery}
  ${latestPostsQuery}
  ${categoriesQuery}
}
`

const PreviewPost = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POSTS, {
    variables: { postID: id },
  })

  // Static Query Data
  if (data) {
    data.templateData.previewSQ = {}
    data.templateData.previewSQ.latestPosts = data.latestPosts
    data.templateData.previewSQ.categories = data.categories
    data.templateData.previewSQ.mainMenu = data.mainMenu
    data.templateData.previewSQ.footerData = {
      menus: data.menus,
      siteOptions: data.siteOptions,
    }
  }

  return (
    <>
      {loading && <LoadingBars />}

      {data && renderTemplate(data.templateData)}

      {error && <pre>{JSON.stringify(error.networkError, null, 2)}</pre>}
    </>
  )
}

export default PreviewPost

const renderTemplate = page => {
  switch (page.pageTemplate) {
    case "home":
      return <HomeTemplate pageContext={page} />
    case "contact":
      return <ContactTemplate pageContext={page} />
    case "news":
      return <NewsTemplate pageContext={page} />
    case "campaign":
      return <CampaignTemplate pageContext={page} />
    case "staff":
      return <StaffTemplate pageContext={page} />
    default:
      return <PageTemplate pageContext={page} />
  }
}
