const imgObject = `
  sourceUrl
  srcSet
  mediaDetails {
    height
    width
  }
`

const childLinks = `
  title
  link
  slug
  childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
    nodes {
      title
      link
      slug
      childPages(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        nodes {
          title
          link
          slug
        }
      }
    }
  }
`

const teaser = `
  title
  excerpt
  link
  slug
  featuredImage {
    ${imgObject}
  }
`

const homeData = `
  headerOptions {
    headerTitle
    headerDescription
  }

  homeServices {
    serviceItems {
      ... on WPGraphQL_Page {
        ${teaser}
        pageSettings {
          homePageFeaturedTeaserExcerpt
        }
      }
    }
  }

  LyfeSection {
    lDescription
    lHeader
    lLink
  }

  avTestimonials {
    avtButtonLink
    avtButtonText
    testimonials {
      heading
      description
      author
    }
  }

  testimonialSection {
    tAuthor
    tLink
    tImage {
      ${imgObject}
    }
    tLinkText
    tPrimaryQuote
    tSecondaryQuote
  }

  statsModule {
    sHeader
    sItems {
      icon
      primaryText
      secondaryText
    }
  }
`
const staffBlock = `
 staffBlock {
    staffBlock {
      staffblockheader
      staffblockpeople {
        ... on WPGraphQL_Staff {
          title
          staffDetails {
            staffType {
              name
            }
            position
            phone
            email
            linkedin
              image {
                sourceUrl
                srcSet
                mediaDetails {
                  sizes {
                    height
                    width
                  }
                }
                sizes
                
            }
          }
        }
      }
    }
  }
`
const postData = `
  id
  title
  excerpt
  slug
  content
  link
  author {
    name
  }
  readingTime {
    readingTime
  }
  date
  content
  seo {
    title
    metaDesc
  }
  featuredImage {
    ${imgObject}
  }

  categories {
    nodes {
      name
      slug
    }
  }

  ArticleTeasersBlock {
    articleTeasersBlock {
      displayLanding
      teaserblockheader
      teaserblockitems {
        ... on WPGraphQL_Post {
          ${teaser}
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
const pageData = `
  id
  title
  depth
  excerpt
  slug
  pageTemplate
  content
  link
  date
  content
  seo {
    title
    metaDesc
  }
  featuredImage {
    ${imgObject}
  }


  TeasersBlock {
    teasersBlock {
      displayLanding
      teaserblockheader
      teaserblockitems {
        ... on WPGraphQL_Page {
          ${teaser}
          type
        }

        ... on WPGraphQL_Post {
          ${teaser}
          type
        }
      }
    }
  }

  statsModule {
    sHeader
    sItems {
      icon
      primaryText
      secondaryText
    }
  }


  legAncestors {
    __typename
    ... on WPGraphQL_Page {
      ${childLinks}
    }
  }
  ${homeData}
`

const navQuery = `
  mainMenu: pages(
    where: { orderby: { field: MENU_ORDER, order: ASC }}
  ) {
    nodes {
      title
      link
      megaMenu {
        megaMenu
        hideMainMenu
      }
      childPages(
        where: { orderby: { field: MENU_ORDER, order: ASC } }
      ) {
        nodes {
          title
          link
          childPages(
            where: { orderby: { field: MENU_ORDER, order: ASC } }
          ) {
            nodes {
              title
              link
            }
          }
        }
      }
    }
  }
`
const latestPostsQuery = `
  latestPosts: posts(first: 13) {
    nodes {
      title
      excerpt
      link
      slug
      featuredImage {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
`
const categoriesQuery = `
  categories: categories {
    nodes {
      name
      slug
    }
  }
`

const footerQuery = `
  menus(where: { slug: "footer" }) {
    nodes {
      name
      menuItems {
        nodes {
          label
          url
        }
      }
    }
  }
  siteOptions {
    siteSettings {
      title
      phoneNumber
      mapLink
      lefttext
      fieldGroupName
      email
      description
      address
      acknowledgement
    }
  }
`

module.exports = {
  pageData,
  postData,
  navQuery,
  footerQuery,
  categoriesQuery,
  latestPostsQuery,
  staffBlock,
}
